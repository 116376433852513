@import '~@pallet/pallet/dist/pallet/pallet.css';

.App {
  text-align: center;
}

.subtext {
  font-weight: lighter;
  font-size: 1.5rem;
}

.mainText {
  margin-top: 25rem;
}